.info {
   padding: 80px 0;
   background-image: url(../images/info-bcg.png);
background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.info__inner {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
   gap: 40px;
@include media("<=810px") {
   flex-wrap: wrap;
    gap: 30px;
    justify-content: space-around;
}
@include media("<=500px") {
    flex-direction: column;
    align-items: center;
}
}

.info__item {
    display: flex;
    flex-direction: column;  
    border-radius: 16px;
        background: rgba(255, 255, 255, 0.16);
    flex: 0 1 47%;
    color: #fff;
    line-height: 1.3;
      
        @include media("<=810px") {
            flex: 0 1 46%;
        }
                 @include media("<=500px") {
                   flex: 0 1 100%;
                 }
    &-big {
        flex: 0 1 100%;
        width: 100%;
    }             
}

.info__image {
    width: 100%;
    img {
        width: 100%;
    }
}



.info__title {
    font-size: 20px;
    font-weight: 700;
    padding: 20px;
    text-transform: uppercase;
    @include media("<=1080px") {
font-size: 20px;
    }
    span {
        font-size: 48px;
    }
}

.info__text {
    font-size: 20px;
    font-weight: 400;
    padding: 0 20px 20px;
    @include media("<=810px") {
        
    }
}

