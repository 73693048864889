.hero {
  
  position: relative;
//  z-index: 2;
  overflow-x: clip;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    
    
  }
  @include media("<=430px") {
    
  }
  
}

.hero__inner {
  position: relative;
  z-index: 3;
  padding-top: 145px;
  padding-bottom: 145px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
  text-align: center;
  color: #fff;
  @include media("<=1080px") {
    margin: 0 auto;
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
    padding-top: 160px;
  }
  @include media("<=630px") {
     padding-top: 200px;
  }
  @include media("<=500px") {
    padding-top: 210px;
    padding-bottom: 100px;
  }
  @include media("<=320px") {
  }
  &-about {
      background-image: url(../images/about-bcg.png);
  }
  &-hotels {
      background-image: url(../images/hotels-bcg.png);
  }
}



.hero__content {
  flex: 0 1 100%;
  width: 100%;
  z-index: 24;
  line-height: 1.2;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 730px;

  justify-content: space-between;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
  
    
  }
  @include media("<=810px") {
    max-width: 750px;
    margin: 0 auto;
    line-height: 1.2;
    flex-direction: column;
   
  }
    @include media("<=500px") {
     width: 100%;
    }
}

.hero__title {
  max-width: 720px;
  text-transform: uppercase;
  font-size:52px;
  margin-bottom: 24px;
  font-weight: 700;
  width: 100%;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
        @include media("<=1440px") {
         
        }
  @include media("<=1280px") {
     font-size: 48px;
    
  }

  @include media("<=1080px") {
    font-size: 42px;
  }
  @include media("<=810px") {
    text-align: center;

    
  }
  @include media("<=500px") {
    font-size: 30px;
   
  }

  @include media("<=410px") {
    font-size: 28px;
  }
}

.hero__text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 30px;
  @include media("<=500px") {
    font-size: 13px;
  }
  span {
    font-weight: 600;
  }
}


.button { 
    text-transform: uppercase;
    border: none;
    width: 100%;
  max-width: 277px;
  display: flex;
    align-items: center;
    justify-content: center;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  padding: 12px;
  width: 100%;

  border-radius: 40px;
    background: #EF0D0D;
  border: 1px #EF0D0D solid;
  transition: all ease-in-out 0.3s;
  position: relative;
  z-index: 25;

  &:hover {
    background-color: transparent;
    color: #EF0D0D;
  }
    @include media("<=1440px") {
      
    }
        @include media("<=810px") {
          margin: 0 auto;
        }
    @include media("<=430px") {
      
    }
        @include media("<=375px") {
          
        }
      
      }


.hero__subtext {
  padding-top: 80px;
  font-size: 28px;
  font-weight: 500;
  max-width: 993px;
  line-height: 1.2;
  text-transform: uppercase;
    @include media("<=430px") {
      font-size: 24px;
    }
}

.hero__slide {
  width: 100%;
  height: 700px;
  position: relative;
  @include media("<=810px") {
    min-height: 520px;
  }
}

.hero__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


