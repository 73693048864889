.features {
  position: relative;
  padding: 60px 0;
  overflow: hidden;
  
  @include media("<=1440px") {

  }
 
}

.features__inner {
  
  display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    padding-top: 50px;
    gap: 16px;
    @include media("<=1440px") {
      

    }
        @include media("<=1080px") {
         
        }
                @include media("<=810px") {
                   flex-direction: column;
                   align-items: center;
                }
        @include media("<=500px") {
          
        }
        &-column {
          padding-top: 40px;
          flex-wrap: nowrap;
        }
}

.features__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  flex: 0 1 31%;
  width: 100%;
  padding: 24px 16px;
  background-color: #fff;
  text-align: center;
  transition: all 0.8s ease 0.2s;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
 
  }
  
  @include media("<=810px") {
     flex: 0 1 100%;

     width: 100%;
  }
  @include media("<=450px") {

  }
 @include media("<=360px") {
   

 }
  &-big {
    flex: 0 1 48%;
  }

}

.features__content {
  position: relative;
  z-index: 3;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  &-2 {
    align-items: flex-end;
  }
}


.features__title {
  text-transform: uppercase;

  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 10px;

@include media("<=1440px") {
  
}
  @include media("<=1280px") {
   
  }

  @include media("<=450px") {
    font-size: 20px;
  }
    @include media("<=380px") {
      
    }
    p {
      font-size: 16px;
      font-weight: 400;
      padding-top: 12px;
      text-transform: none;
    }
}

.features__text {
    display: flex;
    gap: 20px;
      flex-direction: column;
    font-size: 16px;
    font-weight: 300;
    line-height: 130%;
 
@include media("<=1080px") {

  }
  @include media("<=810px") {
    font-size: 14px;

  }

  @include media("<=430px") {
    font-size: 12px;
  }
}


.features__list {
  font-size: 16px;
    line-height: 130%;
    list-style: disc;
    padding-left: 15px;
}

.features__image {
  width: 100%;
  position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  img {
    width: 100%;
    display: block;
  }
}

.features__flex {
  display: flex;
  gap: 15px;
  flex: 0 1 47%;
  align-items: center;
  
}

.features__icon {
  max-width: 40px;
  width: 100%;
}

.blog__item {
  flex: 0 1 48%;
  padding: 32px;
  border-radius: 8px;
    background: #FFF;
}

.blog__image {
  width: 100%;
  margin-bottom: 30px;
  img {
    width: 100%;
  }
}
